import React from "react";
import ComingSoon from "../components/ComingSoon.tsx";

const Parlor = () => {
  return (
    <div>
      <ComingSoon />
    </div>
  );
};

export default Parlor;
