import React from "react";
import ComingSoon from "../components/ComingSoon.tsx";

const Hospital = () => {
  return (
    <div>
      <ComingSoon />
    </div>
  );
};

export default Hospital;
