import React from "react";
import Carousel from "react-bootstrap/Carousel";

const HomeCarousel = () => {
  return (
    <Carousel data-bs-theme="dark" className="coruselSizing">
      <Carousel.Item>
        <img
          className="d-block w-100 carousel"
          src="img/Teesta-Barrage.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousel"
          src="img/airport.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>
  );
};

export default HomeCarousel;
