import React from "react";
import ComingSoon from "../components/ComingSoon.tsx";

const Restaurant = () => {
  return (
    <div>
      <ComingSoon />
    </div>
  );
};

export default Restaurant;
