import React from "react";
import SEO from "../components/SEO.tsx";
import ComingSoon from "../components/ComingSoon.tsx";

const Ambulance = () => {
  return (
    <>
      <SEO title="Ambulance" description="" />
      <div>
        <p>
          <ComingSoon />
        </p>
      </div>
    </>
  );
};

export default Ambulance;
