import React from "react";
import { FaFacebookSquare, FaYoutube, FaWhatsappSquare } from "react-icons/fa";
// import { FaSquareXTwitter } from "react-icons/fa6";
import { FaPaperPlane } from "react-icons/fa";
import TextField from "@mui/material/TextField";

import "./Footer.css";

const Footer = () => {
  return (
    <div>
      <hr />
      <div className="d-inline-block col-12 col-md-12 col-xl-6 p-5">
        <p className="d-flex fs-2 justify-content-center">About us</p>
        <p className="text-justify">
          Our goal is to bring together various facilities in the Lalmonirhat
          district on a single website. This will ensure that everyone has easy
          access to information and resources. We aim to gather information and
          educational content about different places by enlisting the help of
          volunteers.
        </p>
        <p className="text-justify">
          We will consistently rate service providers with a 5-star system to
          help users find quality services. If you would like to contribute
          information, please fill out the form and submit it, or contact us via
          social media. We value your input and would love to hear from you.
        </p>
      </div>
      <div className="d-inline-block col-12 col-md-12 col-xl-6 p-3">
        <p
          className="rounded d-flex justify-content-center fs-5 m-auto my-3"
          style={{ width: "40%", height: "40px" }}
        >
          Send your thoughts
        </p>
        <form
          id="contact-form"
          className="col-md-offset-1 scrollimation fade-left"
          action=""
          method=""
        >
          <TextField
            // helperText="Please enter your name"
            id="demo-helper-text-aligned"
            className="w-100 py-1"
            label="Name"
          />
          <TextField
            // helperText="Please enter your email"
            id="demo-helper-text-aligned"
            className="w-100 py-1"
            label="Email"
          />

          <TextField
            id="outlined-multiline-static"
            className="w-100 py-1"
            label="Message"
            multiline
            rows={5}
            // helperText="Please enter your message"
          />
          <div className="d-flex justify-content-center pt-2">
            <button
              name="submit"
              type="submit"
              className="btn bg-primary text-light"
              data-error-message="Error!"
              data-sending-message="Sending..."
              data-ok-message="Message Sent"
            >
              Send Message
              <FaPaperPlane className="ms-2" />
            </button>
          </div>
        </form>
      </div>
      <div
        className="d-flex align-items-center flex-column mb-3"
        // style="height: 200px;"
      >
        <div className="p-2">
          {" "}
          <img
            src="lalmonirhat512.png"
            alt="Logo of Lalmonirhat City"
            className=""
            style={{ width: "150px", height: "150px" }}
          />
        </div>
        <div className="pt-2">
          {" "}
          <p className="fs-1">
            Lalmonirhat <span className="text-danger">City</span>
          </p>
        </div>
        <span className="fs-5">পরিকল্পনা ও বাস্তবায়নে</span>
        <hr style={{ height: "0", width: "50%" }} />
        <img
          src="img/hsdf.png"
          alt="HSDF"
          style={{ width: "150px", height: "150px" }}
        />
        <div className="mt-auto fs-6">
          <p>HUMAN AND SOCIAL DEVELOPMENT FOUNDATION</p>
        </div>
        <div className="mt-auto fs-6">
          <p>এক সঙ্গে লালমনিরহাটের বিভিন্ন তথ্য</p>
        </div>
        <span>www.lalmonirhat.city</span>
      </div>
      <hr className="mx-5" />
      <div className="col-12 d-flex justify-content-center">
        <p>Visite our social site</p>
      </div>
      <div className="col-12 d-flex justify-content-center">
        <a href="/">
          <FaFacebookSquare
            className="m-1"
            style={{ width: "50px", height: "50px", color: "#1877F2" }}
          />
        </a>
        <a
          href="https://www.youtube.com/@lalmonirhat-city
"
        >
          <FaYoutube
            className="m-1"
            style={{ width: "50px", height: "50px", color: "	#FF0000" }}
          />
        </a>
        <a href="https://chat.whatsapp.com/BAQy04SlpxhGfY4VvIYsbL">
          <FaWhatsappSquare
            className="m-1"
            style={{ width: "50px", height: "50px", color: "#075e54" }}
          />
        </a>
      </div>
      <hr className="p-1" />
    </div>
  );
};

export default Footer;
