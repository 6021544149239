import React from "react";
import { Helmet } from "react-helmet-async";

const SEO: React.FC<{ title: string; description: string }> = ({
  title,
  description,
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description">{description}</meta>
    </Helmet>
  );
};

export default SEO;
