import React from "react";

import HomeCarousel from "../components/home/HomeCarousel.tsx";
import HomeLogos from "../components/home/HomeLogos.tsx";
import "./Home.css";

const Home = () => {
  return (
    <div>
      <HomeCarousel />
      <HomeLogos />
    </div>
  );
};

export default Home;
