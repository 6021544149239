import React from "react";
import { HelmetProvider } from "react-helmet-async";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.css";
import Home from "./pages/Home.tsx";
import ResponsiveNavbar from "./layout/Navbar.tsx";
import Footer from "./layout/Footer.tsx";
import Ambulance from "./pages/Ambulance.tsx";
import Arguments from "./pages/Arguments.tsx";
import BloodDonor from "./pages/BloodDonor.tsx";
import Bus from "./pages/Bus.tsx";
import Courier from "./pages/Courier.tsx";
import Doctor from "./pages/Doctor.tsx";
import Electricity from "./pages/Electricity.tsx";
import Emargency from "./pages/Emargency.tsx";
import Entrepreneur from "./pages/Entrepreneur.tsx";
import Firefighter from "./pages/Firefighter.tsx";
import Hospital from "./pages/Hospital.tsx";
import Hotel from "./pages/Hotel.tsx";
import Job from "./pages/Job.tsx";
import News from "./pages/News.tsx";
import Parlor from "./pages/Parlor.tsx";
import PlacesOfInterest from "./pages/PlacesOfInterest.tsx";
import Police from "./pages/Police.tsx";
import Railway from "./pages/Railway.tsx";
import Restaurant from "./pages/Restaurant.tsx";
import School from "./pages/School.tsx";
import Shopping from "./pages/Shopping.tsx";
import Taxi from "./pages/Taxi.tsx";
import Teacher from "./pages/Teacher.tsx";
import Video from "./pages/Video.tsx";
import Website from "./pages/Website.tsx";
import Worker from "./pages/Worker.tsx";
import History from "./pages/History.tsx";
import Error from "./layout/Error.tsx";

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ResponsiveNavbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/history" element={<History />} />
          <Route path="/ambulance" element={<Ambulance />} />{" "}
          <Route path="/arguments" element={<Arguments />} />
          <Route path="/blood" element={<BloodDonor />} />
          <Route path="/bus" element={<Bus />} />
          <Route path="/courier" element={<Courier />} />
          <Route path="/doctor" element={<Doctor />} />
          <Route path="/electricity" element={<Electricity />} />
          <Route path="/emargency" element={<Emargency />} />
          <Route path="/entrepreneur" element={<Entrepreneur />} />
          <Route path="/firefighter" element={<Firefighter />} />
          <Route path="/hospital" element={<Hospital />} />
          <Route path="/hotel" element={<Hotel />} />
          <Route path="/job" element={<Job />} />
          <Route path="/news" element={<News />} />
          <Route path="/parlor" element={<Parlor />} />
          <Route path="/places-of-interest" element={<PlacesOfInterest />} />
          <Route path="/police" element={<Police />} />
          <Route path="/railway" element={<Railway />} />
          <Route path="/restaurant" element={<Restaurant />} />
          <Route path="/school" element={<School />} />
          <Route path="/shopping" element={<Shopping />} />
          <Route path="/taxi" element={<Taxi />} />
          <Route path="/teacher" element={<Teacher />} />
          <Route path="/video" element={<Video />} />
          <Route path="/website" element={<Website />} />
          <Route path="/worker" element={<Worker />} />
          <Route path="/*" element={<Error />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
