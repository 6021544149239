import React from "react";
import HistoryOfLalmoni from "../components/history/HistoryOfLalmoni.tsx";

const History = () => {
  return (
    <div>
      <HistoryOfLalmoni />
    </div>
  );
};

export default History;
