import React from "react";
import PopularPerson from "./PopularPerson.tsx";

const HistoryOfLalmoni = () => {
  return (
    <div>
      <div className="px-3 text-justify">
        <h1>লালমনিরহাট জেলার নামকরন</h1>
        <p>লালমনিরহাট জেলার নামকরন নিয়ে কয়েকটি মত পাওয়া যায়। সেগুলো হলো-</p>
        <ol>
          <li className="py-1">
            ১৭৮৩ সালে সাধারণ কৃষকদের অধিকার প্রতিষ্ঠার জন্য লালমনি নামে এক মহিলা
            কৃষক নেতা নুরুলদিনকে সাথে নিয়ে বৃটিশ সৈন্য ও জমিদারদের বিরুদ্ধে লড়াই
            করে নিজেদের জীবন উৎসর্গ করে। সেই থেকে এ জায়গার নাম হয় ‘লালমনি’।
          </li>
          <li className="py-1">
            অন্য মতানুসারে উনবিংশ শতাব্দিতে যখন বেংগল ডুয়ার্স রেল লাইন তৈরির
            জন্য মাটি খননের কাজ চলছিল তখন শ্রমিকরা এখানে মাটিন নিচে লাল পাথর
            দেখতে পায়। সেই থেকে এ জায়গার নাম হয়েছে ‘লালমনি’।
          </li>
          <li className="py-1">
            অন্য আরেকটি মতানুসারে বৃটিশ রেলওয়ে যে মহিলার জমি অধিগ্রহণ করেছিল তার
            নাম ছিল লালমনি। তার অবদানের স্বীকৃতিস্বরূপ এলাকার লোকজন এ জায়গার নাম
            রাখে ‘লালমনি’।
          </li>
        </ol>
        <p>
          তবে সবচেয়ে বেশি গ্রহণ যোগ্য মত হলো ১৭৮৩ সালে সাধারণ কৃষকদের অধিকার
          প্রতিষ্ঠার জন্য লালমনি নামে এক মহিলা কৃষক নেতা নুরুলদিনকে সাথে নিয়ে
          বৃটিশ সৈন্য ও জমিদারদের বিরুদ্ধে লড়াই করে নিজেদের জীবন উৎসর্গ করে। সেই
          থেকে এ জায়গার নাম হয় ‘লালমনি’। কালের বিবর্তনে 'হাট' শব্দটি 'লালমনি'
          শব্দের সাঙ্গে যুক্ত হয়ে নামকরণ হয়েছে 'লালমনিরহাট' ।
        </p>
        <h2 className="py-2 text-center">উল্লেখযোগ্য ব্যক্তিত্ব</h2>
        <PopularPerson />
      </div>
    </div>
  );
};

export default HistoryOfLalmoni;
