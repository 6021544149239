import React from "react";

const ComingSoon = () => {
  return (
    <div>
      <p className="d-flex justify-content-center">
        Work in progress You will get full service very soon.
      </p>
    </div>
  );
};

export default ComingSoon;
