import React from "react";

const Error = () => {
  return (
    <div>
      {" "}
      <p className="d-flex justify-content-center">404! Page not found.</p>
    </div>
  );
};

export default Error;
