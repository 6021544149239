import React from "react";
import Accordion from "react-bootstrap/Accordion";

const PopularPerson = () => {
  return (
    <div>
      {" "}
      <div className="card mb-3 m-auto" style={{ height: "50%", width: "70%" }}>
        <div className="row g-0">
          <div className="col-md-4">
            <img
              src="img/তমিজউদ্দীন প্রামাণিক.jpg"
              className="img-fluid rounded-start"
              alt="তমিজউদ্দীন প্রামাণিকের ছবি"
            />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h3 className="card-title">তমিজউদ্দীন প্রামাণিক</h3>
              <p>জন্ম: ১৯৩১ খ্রিষ্টাব্দে</p>
              <p>মৃত্যু: ২৭ মে, ১৯৭১ খ্রিষ্টাব্দে</p>
              <p>পরিচিতির কারণ: বীর বিক্রম</p>
            </div>
          </div>
        </div>
      </div>
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            তমিজউদ্দীন প্রামাণিক সম্পর্কে যানতে ক্লিক করুণ
          </Accordion.Header>
          <Accordion.Body>
            <p>
              শহীদ তমিজউদ্দীন প্রামাণিক স্বাধীনতা যুদ্ধের একজন বীর মুক্তিযোদ্ধা।
              স্বাধীনতা যুদ্ধে তার সাহসিকতার জন্য বাংলাদেশ সরকার তাকে বীর বিক্রম
              খেতাব প্রদান করে।
            </p>
            <p>
              ১৯৭১ সালে গাইবান্ধার মুজাহিদ ব্যাটালিয়নে কর্মরত ছিলেন বীর বিক্রম
              তমিজউদ্দীন প্রামাণিক । মার্চ মাসের প্রথমার্ধে তিনি ছুটিতে গ্রামে
              আসেন এবং একই সঙ্গে মুক্তিযুদ্ধ শুরু হলে তিনি ইপিআর ও ছাত্র-যুবক
              সমন্বয়ে গড়া প্রতিরোধ যোদ্ধাদের সঙ্গে যোগ দেন এবং তাদের সঙ্গে
              এপ্রিল-মে মাসে কুড়িগ্রাম জেলার নাগেশ্বরী ও ভূরুঙ্গামারীসহ কয়েকটি
              স্থানে প্রতিরোধ যুদ্ধে অংশ নেন।
            </p>
            <p>
              পাকিস্তানি সেনারা প্রতিরোধ যোদ্ধাদের পাটেশ্বরী ডিফেন্সে ব্যাপক
              গোলাবর্ষণ শুরু করে ১৯৭১ সালের ২৬ মে রংপুর ও কুড়িগ্রাম জেলার
              সীমান্তে। ফলে তাঁদের পক্ষে সেখানে টিকে থাকা সম্ভব হলো না।
              মুক্তিযুদ্ধের আগেই এখানে প্রতিরোধ যোদ্ধাদের একটি ডিফেন্স ছিল। তারা
              ছিলেন ইপিআর-মুজাহিদ-আনসার ও বেশ কয়েকজন ছাত্র-যুবক। আর এই ডিফেন্সে
              অবস্থান নিয়ে পাকিস্তানি সেনাদের মোকাবিলা করতেন। ২৭ মে ভোরবেলা
              থানা হেডকোয়ার্টার্সে বসে আছেন প্রতিরোধ যোদ্ধাদের অধিনায়ক
              ক্যাপ্টেন নওয়াজেশসহ কয়েকজন। এমন সময় দুজন লোক সেখানে আসে। জানাল,
              তাদের বাড়ি উলিপুর উপজেলায়। তারা আরও বলল, পাকিস্তানি সেনারা ধরলা
              নদী অতিক্রম করেনি। তাই মুক্তিযোদ্ধাদের পুনরায় পাটেশ্বরীতে ডিফেন্স
              নেওয়া প্রয়োজন। এ জন্য তারা দুজন খুব জোরাজুরি করতে থাকে। ওই
              দুজনের সংবাদের ভিত্তিতে প্রতিরোধ যোদ্ধারা আবার পাটেশ্বরী ডিফেন্সে
              অবস্থান নেওয়ার সিদ্ধান্ত নিলেন। একটি ট্রাক, একটি পিকআপ ও একটি জিপ
              গাড়িতে রওনা হলেন প্রায় ১০০ জন যোদ্ধা। তাঁদের সঙ্গে রয়েছেন
              মুজাহিদ বাহিনীর তমিজউদ্দীন প্রামাণিক। আসলে ওই দুজন ছিল
              পাকিস্তানিদের অনুচর। পাকিস্তানিরা তাদের পাঠিয়ে দিয়ে পাটেশ্বরীতে
              অ্যামবুশ করে প্রতিরোধ যোদ্ধাদের অপেক্ষায় ছিল। প্রতিরোধ যোদ্ধাদের
              বহনকারী গাড়ি সেখানে পৌঁছামাত্র পাকিস্তানিরা আক্রমণ করে। প্রতিরোধ
              যোদ্ধারা দিশেহারা হয়ে পড়েন। প্রাথমিক হতচকিত অবস্থা কাটিয়ে তারা
              গাড়ি থেকে লাফিয়ে নিচে নেমে পাল্টা আক্রমণের চেষ্টা করলেন।
              প্রতিরোধ যোদ্ধারা খণ্ড খণ্ড যুদ্ধে লিপ্ত হন। কিন্তু পাকিস্তানি
              সেনাদের প্রচণ্ড আক্রমণের মুখে শেষ পর্যন্ত তারা ছত্রভঙ্গ হয়ে
              গেলেন। শহীদ হলেন বেশ কয়েকজন। এর পরও তাঁদের অন্যতম অধিনায়ক
              মুজাহিদ বাহিনীর অনারারি ক্যাপ্টেন তমিজউদ্দীন প্রামাণিক সংঘর্ষস্থল
              পরিত্যাগ না করে একাই সাহসিকতার সঙ্গে যুদ্ধ করতে থাকেন। তার গুলিতে
              বেশ কয়েকজন শত্রুসেনা হতাহত হয়। এই অসম যুদ্ধের একপর্যায়ে তিনিও
              শত্রুর গুলিতে শহীদ হন।[
              <a href="https://bn.wikipedia.org/wiki/%E0%A6%A4%E0%A6%AE%E0%A6%BF%E0%A6%9C%E0%A6%89%E0%A6%A6%E0%A7%8D%E0%A6%A6%E0%A7%80%E0%A6%A8_%E0%A6%AA%E0%A7%8D%E0%A6%B0%E0%A6%BE%E0%A6%AE%E0%A6%BE%E0%A6%A3%E0%A6%BF%E0%A6%95#:~:text=%E0%A6%B6%E0%A6%B9%E0%A7%80%E0%A6%A6%20%E0%A6%A4%E0%A6%AE%E0%A6%BF%E0%A6%9C%E0%A6%89%E0%A6%A6%E0%A7%8D%E0%A6%A6%E0%A7%80%E0%A6%A8%20%E0%A6%AA%E0%A7%8D%E0%A6%B0%E0%A6%BE%E0%A6%AE%E0%A6%BE%E0%A6%A3%E0%A6%BF%E0%A6%95%20(%E0%A6%9C%E0%A6%A8%E0%A7%8D%E0%A6%AE%3A%20%E0%A7%A7%E0%A7%AF%E0%A7%A9%E0%A7%A7,%E0%A6%AC%E0%A7%80%E0%A6%B0%20%E0%A6%AC%E0%A6%BF%E0%A6%95%E0%A7%8D%E0%A6%B0%E0%A6%AE%20%E0%A6%96%E0%A7%87%E0%A6%A4%E0%A6%BE%E0%A6%AC%20%E0%A6%AA%E0%A7%8D%E0%A6%B0%E0%A6%A6%E0%A6%BE%E0%A6%A8%20%E0%A6%95%E0%A6%B0%E0%A7%87%E0%A5%A4">
                ১
              </a>
              ]
            </p>
            <div>
              তথ্য সূত্র
              <hr />
              ১.{" "}
              <a href="https://bn.wikipedia.org/wiki/তমিজউদ্দীন_প্রামাণিক">
                https://bn.wikipedia.org/wiki/তমিজউদ্দীন_প্রামাণিক
              </a>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default PopularPerson;
