import React from "react";
import homoLogosData from "../logoData.json";

const HomeLogos = () => {
  const homeLogosCard = homoLogosData.logosData.map((item, index) => {
    return (
      <>
        <div className="d-inline-block p-1 col-4 col-sm-4 col-md-3 col-xxl-2">
          <a href={item.to} className="text-decoration-none">
            <div className="card m-1">
              <img
                src={item.link}
                className="card-img-top imgHeight"
                alt="hospital"
              />
              <div className="card-footer">
                <p className="text-center">{item.name}</p>
              </div>
            </div>
          </a>
        </div>
      </>
    );
  });

  return <div>{homeLogosCard}</div>;
};

export default HomeLogos;
