import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

const ResponsiveNavbar = () => {
  return (
    <div>
      <Navbar expand="lg" className="bg-body-tertiary px-3 fixed-top">
        <Navbar.Brand href="/">
          <img
            alt="Lalmonirhat City logo"
            src="lalmonirhat512.png"
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{" "}
          Lalmonirhat City
        </Navbar.Brand>{" "}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/" className="d-flex justify-content-center">
              সেবা
            </Nav.Link>
            <Nav.Link href="/History" className="d-flex justify-content-center">
              ইতিহাস
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};
export default ResponsiveNavbar;
