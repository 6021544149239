import React from "react";
import ComingSoon from "../components/ComingSoon.tsx";

const Arguments = () => {
  return (
    <>
      <p>
        <ComingSoon />
      </p>
    </>
  );
};

export default Arguments;
